<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>

              <div class="d-flex align-items-center">
                <!-- <el-button class="btn new-btn mr-3" @click="$router.push({path: '/ordem'})">Cadastrar Novo</el-button> -->
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>

            <div class="col-12">
              <el-table :data="queriedData.filter(data => !searchQuery || data.local.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"></el-table-column>
                <el-table-column :min-width="25" v-if="total > 0" align="right" label="Ações">
                  <template v-slot="props">
                    <base-button v-if="!props.row.deposit_receipt" @click.native="handleDepositReceipt(props.row, props.$index)" class="edit btn-link" type="gray" size="sm" icon title="Cadastar comprovante">
                      <i class="fa fa-barcode"></i>
                    </base-button>

                    <a v-if="props.row.deposit_receipt" target="_blank" :href="props.row.deposit_receipt" class="text-info deposit-receipt-link">
                      <i class="fa fa-barcode"></i>
                    </a>

                    <base-button v-if="props.row.status === 2" @click.native="handleConfirmPaid(props.$index, props.row)" class="edit btn-link" type="success" size="sm" icon title="Aprovar">
                      <i class="fa fa-check"></i>
                    </base-button>

                    <base-button v-if="props.row.status === 2" @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon title="Cancelar depósito">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>

                    <base-button title="Alterar status para aguardando pagamento" v-if="props.row.status !== 2" @click.native="handleUndo(props.$index, props.row)" class="remove btn-link" type="info" size="sm" icon>
                      <i class="fa fa-undo-alt"></i>
                    </base-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div slot="footer" class="col-12 mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <p class="card-category table-footer" v-if="total > 0">
                Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
              </p>
              <p class="card-category table-footer" v-if="total === 0">
                Nenhum registro encontrado.
              </p>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="modal" headerClasses="justify-content-center" centered>
      <h4 slot="header" class="title title-up">
        Cadastrar comprovante de depósito
      </h4>

      <div class="col-12 p-0 mb-3">
        <div class="form-group">
          <label for="deposit_receipt">Comprovante</label>
          <input type="file" class="form-control" @change="changeDepositReceipt" />
          <label for="deposit_receipt" class="form-control">{{dataDepositReceipt.deposit_receipt.name ? dataDepositReceipt.deposit_receipt.name : 'Selecionar Comprovante'}}</label>
        </div>
      </div>

      <template slot="footer">
        <base-button :disabled="loadingDepositiReceipt" type="danger" @click.native="cancelDepositReceipt()">Cancelar</base-button>
        <base-button :disabled="loadingDepositiReceipt" type="primary" @click.native="saveDepositReceipt()">{{ loadingDepositiReceipt ? 'Salvando...' : 'Salvar' }}</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button } from 'element-ui'
import { BasePagination, Modal } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'
export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      modal: false,
      dataDepositReceipt: { deposit_receipt: {} },
      loadingDepositiReceipt: false
    }
  },
  methods: {
    handleUndo (index, row) {
      swal({
        title: 'Você tem certeza?',
        text: `Você está prestes a alterar o status do depósito "${row.id}". para "Aguardando Pagamento"`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim, eu quero confirmar!',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          swal({
            title: 'Alterando',
            text: `Aguarde, estamos alterando o depósito "${row.id}".`,
            type: 'info',
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.undo(row).then(status => {
            if (status) {
              swal({
                title: 'Alterado!',
                text: `Você alterou o depósito "${row.id}"`,
                type: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal({
                title: 'Oops!',
                text: `Ocorreu um erro ao alterar o depósito "${row.id}"`,
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    handleDepositReceipt (row, index) {
      this.dataDepositReceipt.id = row.id
      this.dataDepositReceipt.deposit_receipt = ''
      this.dataDepositReceipt.index = index
      this.modal = true
    },
    changeDepositReceipt (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const [file] = files
      const { type, size } = file

      if (
        type !== 'application/pdf' &&
        type !== 'image/png' &&
        type !== 'image/jpeg'
      ) {
        this.showAlert(
          'error',
          'Arquivo inválido!',
          true,
          'O arquivo deve ser no formato PNG, JPG ou PDF.'
        )
        return
      }

      if (size > 2097152) {
        this.showAlert(
          'error',
          'Arquivo inválido!',
          true,
          'O arquivo deve conter o tamanho de até 2MB.'
        )
        return
      }

      var reader = new FileReader()

      reader.onload = e => {
        vue.dataDepositReceipt.deposit_receipt_file = e.target.result
      }

      reader.readAsDataURL(file)
      this.dataDepositReceipt.deposit_receipt = file
    },
    cancelDepositReceipt () {
      this.dataDepositReceipt = { deposit_receipt: {} }
      this.modal = false
    },
    async saveDepositReceipt () {
      if (this.dataDepositReceipt.deposit_receipt.name) {
        this.loadingBillet = true
        this.error = false

        const url = '/bank-log/' + this.dataDepositReceipt.id
        const data = {
          deposit_receipt: this.dataDepositReceipt.deposit_receipt_file
        }

        const res = await this.$http.put(url, data)

        if (res.status === 200) {
          this.modal = false
          swal({
            title: 'Cadastrado!',
            text: 'Comprovante salvo com sucesso',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.getData()
        } else {
          swal({
            title: 'Oops!',
            text: 'Ocorreu um erro ao salvar comprovante',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
          this.error = true
        }
        this.loadingBillet = false
      } else {
        swal({
          title: 'Oops!',
          text: 'Favor, selecione o comprovante',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
        this.error = true
      }
    },
    async undo (row) {
      const data = JSON.parse(JSON.stringify(row))
      data.status = 2
      let status
      try {
        status = (await this.$http.put(`bank-log/${row.id}`, data)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToConfirm = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToConfirm >= 0) {
          this.tableData[indexToConfirm].status = 2
          // this.tableData.splice(indexToDelete, 1);
        }
        return true
      }
      return false
    },
    handleConfirmPaid (index, row) {
      swal({
        title: 'Você tem certeza?',
        text: `Você está prestes a confirmar o depósito "${row.id}".`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim, eu quero confirmar!',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          swal({
            title: 'Confirmando',
            text: `Aguarde, estamos confirmando o depósito "${row.id}".`,
            type: 'info',
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.confirmPaid(row).then(status => {
            if (status) {
              swal({
                title: 'Confirmado!',
                text: `Você confirmou o depósito "${row.id}"`,
                type: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal({
                title: 'Oops!',
                text: `Ocorreu um erro ao confirmar o depósito "${row.id}"`,
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    async confirmPaid (row) {
      const data = JSON.parse(JSON.stringify(row))
      data.status = 1
      let status
      try {
        status = (await this.$http.put(`bank-log/${row.id}`, data)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToConfirm = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToConfirm >= 0) {
          this.tableData[indexToConfirm].status_label = 'Confirmado'
          // this.tableData.splice(indexToDelete, 1);
        }
        return true
      }
      return false
    },
    handleDelete (index, row) {
      swal({
        title: 'Você tem certeza?',
        text: `Você está prestes a cancelar o depósito "${row.id}".`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim, eu quero cancelar!',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          swal({
            title: 'Cancelando',
            text: `Aguarde, estamos cancelando o depósito "${row.id}".`,
            type: 'info',
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal({
                title: 'Cancelado!',
                text: `Você cancelou o depósito "${row.id}"`,
                type: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal({
                title: 'Oops!',
                text: `Ocorreu um erro ao cancelar o depósito "${row.id}"`,
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      let status
      try {
        status = (await this.$http.delete(`bank-log/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData[indexToDelete].status = 'Cancelado'
          // this.tableData.splice(indexToDelete, 1);
        }
        return true
      }
      return false
    },
    showAlert (type, title, buttons, message, timer) {
      const fire = {
        type: type,
        title: title,
        text: message,
        showConfirmButton: buttons,
        confirmButtonClass: 'btn btn-success btn-fill',
        buttonsStyling: false
      }
      if (!buttons) {
        fire.timer = 2000
      }
      fire.timer = timer > 2000 ? timer : fire.timer
      swal(fire)
    },
    async getData () {
      const url = 'financial/getBankLog?type=SAIDA&receiver=Transferência'
      const { data } = await this.$http.get(url)
      this.tableData = data.data

      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 20
        },
        {
          prop: 'local',
          label: 'Banco',
          minWidth: 50
        },
        {
          prop: 'agency',
          label: 'Agência',
          minWidth: 30
        },
        {
          prop: 'number',
          label: 'Conta',
          minWidth: 30
        },
        {
          prop: 'holder',
          label: 'Titular da Conta',
          minWidth: 60
        },
        {
          prop: 'cpf_cnpj',
          label: 'CPF/CNPJ',
          minWidth: 40
        },
        {
          prop: 'value',
          label: 'Valor',
          minWidth: 30
        },
        {
          prop: 'created',
          label: 'Data do Pedido',
          minWidth: 30
        },
        {
          prop: 'status_label',
          label: 'Status',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['title', 'type']
    }
  },
  mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    getRole: 'getData',
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    Modal
  },
  computed: {
    getRole () {
      if (this.$route.path.search('gerador') >= 0) return 'gerador'
      if (this.$route.path.search('entidade') >= 0) return 'entidade'
      return false
    },
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
    currentTable () {
      const msg = `lista de saques ${this.getRole}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
.deposit-receipt-link {
  width: 20px;
  height: 15px;
  display: flex;
  float: left;
  margin-top: 9px;
}
</style>
